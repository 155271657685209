<template>
    <div>
        <nav class="navbar navbar-expand-lg navbar-dark">
            <div class="container-xl">
                <div class="navbar-brand">實聯快疫通<small class="ml-3">Speed PASS</small></div>
            </div>
        </nav>

        <div class="container py-5">
            <div class="jumbotron text-center">
                <h1 class="display-4">{{userName}}</h1>
                <h2>{{locationName}}</h2>
                <h2>隨行人數：{{attendance}}</h2>
                <h3 class="mb-4">{{ this.moment(new Date()).format('YYYY/MM/DD HH:mm:ss') }}</h3>
                <p class="lead">
                    已完成實聯制登記，感謝您的配合。<br>
                    請將完成畫面出示給門市人員確認，確認後即可入場。<br><br>
                    {{locationName}}與您共同防疫。
                </p>
            </div>

            <footer class="my-5 pt-5 text-muted text-small">
                <p class="mb-1">揚揚創新科技有限公司 YOUNG SQUARE CREATECH</p>
                <p class="mb-3">
                    Email: <a href="mailto:info@yscreatech.com?subject=我要回報&body=內容">info@yscreatech.com</a>
                </p>
                <p class="mb-1">© 2014-2021 Young Square Createch LTD.</p>
            </footer>
            <div class="login_power">power by <span><img src="/images/img_logo_w.png"></span></div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'


    export default {
        name: "UserTrackingResult",
        created() {
            this.userName = this.$route.params.userName
            this.locationName = this.$route.params.locationName
            this.createDate = this.$route.params.createDate
            this.attendance = this.$route.params.attendance
        },
        data: function () {
            return {
                userName: '',
                locationName: '',
                createDate: '',
                attendance: '0'
            }
        },
        methods: {
            moment: function () {
                return moment()
            }
        }
    }
</script>

<style scoped>

</style>
